import objectid from 'bson-objectid'

export default {
  objectid,
  getUrlParams
}

function getUrlParams (url?: string) {
  const _url = url || window.location.href;
  const _urlParams = _url.match(/([?&])(.+?=[^&]+)/igm);
  return _urlParams ? _urlParams.reduce((a: any, b: any) => {
    const value = b.slice(1).split('=');
    a[value[0]] = value[1]
    return a;
  }, {}) : {};
}

