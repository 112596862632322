import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

// import '@/plugin/rem'
// import 'amfe-flexible'
// import 'lib-flexible/flexible'

import vant from '@/plugin/vant'
import nutui from '@/plugin/nutui'
import meatou from '@/plugin/meatou'



const app = createApp(App)

app
  .use(createPinia())
  .use(router)
  .use(vant)
  .use(nutui)
  .use(meatou)


  .mount('#app')
