import linq from 'linq'
import { isEmpty } from 'j4tools'

function render (fieldList: Meatou.VUE.field[], form: Meatou.VUE.form) {

  fieldList = linq.from(fieldList).orderBy((f: any) => f.order).toArray()

  // 初始化数据

  form.data = form.default
  form.rules = {}
  form.fields = []

  // 字段处理
  for (const field of fieldList) {

    // 数据处理
    //  if (['上传文件', '上传图片'].includes(field.label!)) {
    //   form.data[field.name] = []
    // } else if (['动态录入', '动态键值对'].includes(field.label!)) {
    //   form.data[field.name] = []
    // } else {
    //   form.data[field.name] = null
    // }

    // 组件处理
    const data: any = {
      name: field.name,
      title: field.title,
      type: field.type,
    }

    // 选择器
    if (field.type === 'n-select') {
      data.options = field.options?.map(r => { return { text: r, value: r } })
    }

    // 上传图片
    if (field.type === 'upload-pic') {
      data.count = field.count
      data.folder = field.folder

      if (!form.data[data.name]) { form.data[data.name] = [] }
    }

    // 上传文件
    if (field.type === 'upload-file') {
      data.count = field.count
      data.folder = field.folder

      if (!form.data[data.name]) { form.data[data.name] = [] }
    }



    // 验证处理
    if (field.required) {

      data.rules = [{ required: true, message: '必填项' }]

    }

    if (field.name === 'mobile') {
      data.rules = [
        { required: true, message: '必填项' },
        {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '手机号格式不对',
          trigger: 'blur'
        }
      ]
    }

    // 数据处理

    form.fields.push(data)

  }

}

export default {
  render
}