import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <Array<RouteRecordRaw>>[
  {
    path: '/',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/application/index/index.vue'),
    meta: { hidden: true, title: '首页' }
  },
  {
    path: '/login',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/application/login/index.vue'),
    meta: { hidden: true, title: '用户登录' }
  }
]