import { App } from 'vue'
import { Button } from 'vant';
import { Col, Row } from 'vant';
import { Image as VanImage } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Step, Steps } from 'vant';
import { Form, Field } from 'vant';
import { Tab, Tabs } from 'vant';
import { NavBar } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { Card } from 'vant';
import { Stepper } from 'vant';
import { Tag } from 'vant';
import { Icon } from 'vant';
import { List } from 'vant';
import { Search } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Calendar } from 'vant';
import { Badge } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Loading } from 'vant';
import { Cascader } from 'vant';

import { Uploader } from 'vant';
import { Grid, GridItem } from 'vant';
import { Divider } from 'vant';
import { Overlay } from 'vant';
import { Notify  } from 'vant';
import { Radio,RadioGroup  } from 'vant';
import { ActionSheet  } from 'vant';
import { Empty  } from 'vant';
import { Toast  } from 'vant';
import { Sticky  } from 'vant';
import { Pagination   } from 'vant';

export default function (app: App) {

  app.use(Button);
  app.use(Col);
  app.use(Row);
  app.use(VanImage);
  app.use(Cell);
  app.use(CellGroup);
  app.use(Step);
  app.use(Steps);
  app.use(Form);
  app.use(Field);
  app.use(Tab);
  app.use(Tabs);
  app.use(NavBar);
  app.use(Sidebar);
  app.use(SidebarItem);
  app.use(Card);
  app.use(Stepper);
  app.use(Tag);
  app.use(Icon);
  app.use(List);
  app.use(Search);
  app.use(Picker);
  app.use(Popup);
  app.use(Calendar);
  app.use(Badge);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Loading);
  app.use(Cascader);

  app.use(Uploader);
  app.use(Grid);
  app.use(GridItem);
  app.use(Divider);
  app.use(Overlay);
  app.use(Notify);
  app.use(Radio);
  app.use(RadioGroup);
  app.use(ActionSheet);
  app.use(Empty);
  app.use(Toast);
  app.use(Sticky);
  app.use(Pagination );

}
