
import { App } from 'vue'

import Tabbar from '@/components/Meatou/Tabbar.vue';
import Form from '@/components/Meatou/Form.vue';
import Cascader from '@/components/Meatou/Cascader.vue';
import Select from '@/components/Meatou/Select.vue';
import Datetime from '@/components/Meatou/Datetime.vue';
import UploadImage from '@/components/Meatou/UploadImage.vue';
import UploadFile from '@/components/Meatou/UploadFile.vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'


import Corp from '@/components/Select/Corp.vue'
import Department from '@/components/Select/Department.vue'

export default function (app: App) {

  app.component('m-form', Form)
  app.component('m-tabbar', Tabbar)
  app.component('m-cascader', Cascader)
  app.component('m-select', Select)
  app.component('m-datetime', Datetime)
  app.component('m-upload-image', UploadImage)
  app.component('m-upload-file', UploadFile)
  app.use(PerfectScrollbar)

  app.component('select-corp', Corp)
  app.component('select-department', Department)

}