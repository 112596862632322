import { App } from 'vue'
import { Button } from '@nutui/nutui';
import { Avatar } from '@nutui/nutui';
import { Price } from '@nutui/nutui';
import { Cell } from '@nutui/nutui';
import { Icon } from '@nutui/nutui';
import { Popup } from '@nutui/nutui';
import { Table  } from '@nutui/nutui';

import '@nutui/nutui/dist/style.css';

export default function (app: App) {

  app.use(Cell);
  app.use(Button);
  app.use(Icon);
  app.use(Avatar);
  app.use(Price);
  app.use(Popup);
  app.use(Table );

}
