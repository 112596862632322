import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  // id: 必须的，在所有 Store 中唯一
  id: "app",
  // a function that returns a fresh state
  state: () => {
    return {
      token:'',
      openid: '',
      unionid: '',
      access_token: '',
      refresh_token: '',
    }
  },
  // optional getters
  getters: {

  },
  // optional actions
  actions: {

  },
})


