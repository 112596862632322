import config from '../../config.json'

export default {
  set,
  get
}

function set (key: string, value: string) {

  localStorage.setItem(`${config.mark}_${key}`, value)

}

function get (key: string) {

  return localStorage.getItem(`${config.mark}_${key}`)

}