function print (name: string, data: any) {
  if (process.env.NODE_ENV !== 'development') { return }
  console.group(name);
  console.log(data)
  console.groupEnd();
}

export default {
  print
}

