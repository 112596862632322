import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw, } from 'vue-router'
import config from '../../config.json'

import application from './modules/application'
import goods from './modules/goods'

export const constantRoutes: RouteRecordRaw[] = [
  ...application,
  {
    path: '/func/product',
    component: () => import( /* webpackChunkName: "func" */ '@/views/product.vue'),
    meta: { title: '转发商品' }
  },
  {
    path: '/func/voucher',
    component: () => import( /* webpackChunkName: "func" */ '@/views/voucher.vue'),
    meta: { title: '优惠券' }
  },
  {
    path: '/func/shop',
    component: () => import( /* webpackChunkName: "func" */ '@/views/shop.vue'),
    meta: { title: '开卡' }
  },
  {
    path: '/func/customer',
    component: () => import( /* webpackChunkName: "func" */ '@/views/customer.vue'),
    meta: { title: '会员信息' }
  }
]

const router = createRouter({
  history: createWebHashHistory(config.path),
  routes: constantRoutes,
  scrollBehavior (to, from, savePosition) {
    if (savePosition) {
      return savePosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
