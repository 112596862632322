<template>
    <router-view />
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
body {
  font-family: "Microsoft YaHei";
background-color: #f7f8fa
}

.dev1 {
  background-color: #409eff;
}

.dev2 {
  background-color: #67c23a;
}

.dev3 {
  background-color: #e6a23c;
}

.dev4 {
  background-color: #f56c6c;
}

.dev5 {
  background-color: #909399;
}
</style>

<style>
.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-justify {
  display: flex;
  justify-content: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
</style>
